.gallery-cont {
  width: 100vw;
  /* height: 95vh; */
  padding-bottom: 5%;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
}

/**************** *****************/
.abt-slider-cont {
  width: 90%;
  height: 80%;
  padding:5%;
  align-content: center;
  justify-items: right;
  border-radius: 30px;
  background-color: #403226;
}

/***********swiper**************/
.swiper {
  align-content: center;
  height: 310px;
  width: 100%;
  margin-left: 2.9%;
}

.swiper-slide {
  position: relative; /* Ensure the pseudo-element positions correctly */
  text-align: center;
  font-size: 18px;
  background: #fff;
  border-radius: 20px;
  display: flex;
  overflow: hidden; /* Prevent overflow from scaling */
}

.swiper-slide img {
  width: 100% !important;
  /* height: 100% !important; */
  border-radius: 20px !important;
  object-fit: cover;
  transition: transform 0.5s ease-in-out, border-radius 0.5s ease-in-out; /* Apply transitions */
}

.slider-img-cont.swiper-slide::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 20px;
  background: linear-gradient(to top, rgba(0, 0, 0, 0.7) 0%, rgba(0, 0, 0, 0) 70%);
  z-index: 1; /* Ensure the gradient is on top of the image */
}

.slider-img-cont:hover img {
  transform: scale(1.1); /* Scale the image on hover */
  border-radius: 30px; /* Adjust border-radius if needed */
}

.slider-img-cont {
  display: flex;
  justify-content: center;
}

.slider-img-cont a {
  position: absolute;
  bottom: 40px;
  color: white;
  padding: 5px;
  font-family: "Space Grotesk", sans-serif;
  font-weight: 700;
  font-size: 25px;
  z-index: 2; /* Ensure the text is above the image */
}

/****************gallery-card********************/
.gallery-custom-col {
  padding: 0px;
  height: 100% !important;
}

.gallery-card {
  height: 310px !important;
  padding: 5px;
}

.gallery-card-title {
  font-family: Space Grotesk, sans-serif;
  font-size: 56px;
  font-weight: 700;
}

.gallery-card-text {
  font-size: 14px;
  color: white;
  font-family: "Roboto", sans-serif;
  margin: 10px 0 0 0;
}

.gallery-custom-row {
  width: 100%;
}

.text {
  display: inline;
  width: 90%;
}

.read-or-hide {
  color: rgb(192, 192, 192);
  cursor: pointer;
}

.NewAboutUs-ReadMore {
  color: #e5646e;
}


/* Responsive Styles */
@media (max-width: 1300px) {
  .abt-slider-cont {
    width: 95%;
    /* height: 70%; */
  }

  .swiper {
    height: 260px;
    margin-left: 2%;
  }

  .swiper-slide img {
    border-radius: 25px;
  }

  .gallery-card {
    height: 260px !important;
  }

  .gallery-card-title {
    font-size: 48px;
  }

  .slider-img-cont a {
    font-size: 22px;
  }
}

@media (max-width:1023px){
  .gallery-custom-row{
    flex-direction: column;
  }
  .gallery-custom-col {
    width: 100% !important;
  }
  .gallery-card{

  }
  .gallery-custom-cont{
    display: flex;
    justify-content: center;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
@media (max-width: 768px) {
  .abt-slider-cont {
    width: 100%;
    height: auto;
    border-radius: 20px;
  }

  .swiper {
    /* height: 220px; */
    /* margin-left: 0; */
    padding-bottom: 10px;
  }

  .swiper-slide img {
    border-radius: 20px;
  }

  .gallery-card {
    height: 220px !important;
  }

  .gallery-card-title {
    font-size: 40px;
  }

  .gallery-card-text {
    font-size: 12px;
  }

  .slider-img-cont a {
    font-size: 20px;
    bottom: 30px;
  }
  .gallery-custom-row{
    flex-direction: column;
  }
  .gallery-custom-col {
    width: 100% !important;
  }
  .gallery-custom-cont{
    display: flex;
    justify-content: center;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

@media (max-width: 480px) {
  .abt-slider-cont {
    width: 100%;
    /* height: 50%; */
    border-radius: 15px;
  }

  .swiper {
    height: 180px;
    margin-left: 0;
  }

  .swiper-slide {
    font-size: 16px;
  }

  .swiper-slide img {
    border-radius: 15px;

  }

  .gallery-card {
    height: 180px !important;
  }

  .gallery-card-title {
    font-size: 32px;
  }

  .gallery-card-text {
    font-size: 10px;
  }

  .slider-img-cont a {
    font-size: 18px;
    bottom: 20px;
  }
  .gallery-custom-col{
    height:100%
  }
}

@media (max-width:426px){
  .slider-slide img{
    height: 100% !important;
    width: 100% !important;
  }
}

@media (mix-width: 1400px){
  .slider-slide img{
    height: 100% !important;
    width: 100% !important;
  }
}