.review-body-cont {
  display: flex;
  position: relative;
  width: 100vw;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.review-row-cont {
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
}

.review-col-cont {
  width: 100%;
  /* height: 900px; */
  border-radius: 30px;
}

.review-col-cont img {
  width: 100%;
  height: 100%;
  border-radius: 30px;
}

/* Right col Cont */
.review-right-col-cont {
  padding-left: 100px;
  padding-top: 100px;
}

.h4-cont {
  width: max-content;
}

/* swiper cont */
.review-swiper-cont {
  /* height: 400px; */
  display: flex;
  align-items: center !important;
  justify-content: center !important;
  text-align: center;
  padding: 20px;
  box-sizing: border-box;
}

.reviewswiper {
  display: flex;
  justify-content: center;
  flex-direction: row;
  height: 100%;
}

.review-swiper-slide {
  position: relative;
  text-align: center;
  font-size: 18px;
  background: #fff;
  border-radius: 20px;
  display: flex;
  overflow: hidden;
  align-items: center;
  justify-content: center;
}

.review-swiper-slide img {
  width: 100% !important;
  height: 100% !important;
  border-radius: 20px;
  object-fit: cover;
  transition: transform 0.5s ease-in-out, border-radius 0.5s ease-in-out;
}

.review-swiper-slide::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 20px;
  z-index: 1;
}

.review-slider-img-cont {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  align-items: center;
  position: relative;
  border: 1px solid #ddd;
}

.review-slider-img-cont:hover img {
  transform: scale(1.1);
  border-radius: 30px;
}

.photo-name-cont {
  width: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 10px;
}

.photo-name-cont img {
  border-radius: 50%;
  width: 80px;
  height: 80px;
}

.photo-name-cont span {
  font-family: Montserrat, serif;
  color: var(--pbmit-xinterio-global-color);
  font-weight: 700;
}

.my-rating-class {
  max-width: 100%;
  margin: 10px 0;
}

.review-descp {
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
  color: white;
  overflow: hidden;
  text-align: left;
}

.review-descp p {
  margin: 0;
  word-wrap: break-word;
  text-overflow: ellipsis;
  white-space: normal;
  text-align: center;
  color: black;
  font-family: Space Grotesk, sans-serif;
}

*{
	margin: 0;
	padding: 0;
}
.main-container
{
	width: 100%;
	min-height: 100vh;
	background-color: #F3FBFB;
	display: flex;
	justify-content: center;
	align-items: center;
}
.main-box
{
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}
.card-container
{
	min-width: 700px;
	display: flex;
	justify-content:space-between;
	align-items: center;
}
.pre-next
{
	height: 40px;
	width: 40px;
}
.pre-next i
{
	font-size: 2.5rem;
	color: #00000030;
	cursor: pointer;
}
.pre-next i:hover
{
	transform: scale(1.1);
}
.review-slider-card
{
	width: 100%;
	padding: 30px 30px;
	background-color: #fff;
	border-radius: 30px;
	cursor: pointer;	
	transition: 0.3s;
}
.review-slider-card:hover
{
	box-shadow:0 0 20px #00000010;
	transform: scale(1.1);
}

.card-top p:nth-child(1),h3
{
	 font-family: 'DM Sans', sans-serif;
	/*font-size:1.5em;*/
}
.card-hading
{
	padding-bottom: 20px;
}
.review-slider-card-bottom
{
	padding-top: 20px;
	display: flex;
  flex-direction: column;
	align-items: center;
}
.prof
{
  width: 50px;
	border-radius: 30%;
	background-color: #eee;
}
.prof img
{
	width:40px;
	height:40px;
	max-width: 100%;
	border-radius: 30%;
	object-fit:cover;
}
.prof-detail
{
	padding-left:10px;
}
.prof-detail p
{
	font-family: 'Ephesis', cursive;

}
.reviewswiper {
  padding-bottom: 40px; /* Add space for the pagination dots */
  position: relative;
}

.reviewswiper .swiper-pagination {
  position: absolute;
  bottom: 20px; /* Position the dots below the Swiper container */
  left: 0;
  right: 0;
  text-align: center;
}

.reviewswiper .swiper-pagination-bullet {
  background-color: #000; /* Customize the color of the pagination dots */
  opacity: 1;
}

.reviewswiper .swiper-pagination-bullet-active {
  background-color: #e5646e; /* Different color for the active dot */
}

.review-main-img-cont{

}
/*medai Querys*/

@media only screen and (max-width: 730px)
{
		.card-container
		{
			flex-direction: column;
			min-height:430px;
			justify-content: space-between;
		}
		.pre-next{
			display: none;
		}
}
/* Responsive Styles */
@media (max-width: 1300px) {
  .review-col-cont {
    /* height: 700px; */
  }

  .review-right-col-cont {
    padding-left: 50px;
    padding-top: 50px;
  }

  .review-swiper-cont {
    /* height: 350px; */

  }

  .review-swiper-slide img {
    border-radius: 15px;
  }

  .photo-name-cont {
    width: 80px;
  }

  .photo-name-cont img {
    width: 70px;
    height: 70px;
  }

  .review-descp p {
    font-size: 16px;
  }
}

@media (max-width: 768px) {
  .review-col-cont {
    /* height: 500px; */
  }

  .review-right-col-cont {
    padding-left: 20px;
    padding-top: 20px;
  }

  .review-swiper-cont {
    /* height: 300px; */
  }

  .review-swiper-slide {
    font-size: 16px;
  }

  .photo-name-cont {
    width: 70px;
  }

  .photo-name-cont img {
    width: 60px;
    height: 60px;
  }

  .review-descp p {
    font-size: 14px;
  }
  .review-row-cont{
    flex-direction: column;
  }
  .custom-review-col{
    display: contents;
  }
}

@media (max-width: 480px) {
  .review-col-cont {
    /* display: contents !important; */
  }
  .custom-review-col{
    display: contents;
  }
  .review-main-img-cont{
    /* display: contents; */
  }
  .review-main-img-cont img{
    width: 100%;
    height: 100%;
  }

  .review-right-col-cont {
    padding-left: 10px;
    padding-top: 10px;
  }

  .review-swiper-cont {
    /* height: 250px; */
    padding: 10px;
  }

  .review-swiper-slide {
    font-size: 14px;
  }

  .photo-name-cont {
    width: 60px;
  }

  .photo-name-cont img {
    width: 50px;
    height: 50px;
  }

  .review-descp p {
    font-size: 12px;
  }
  .review-row-cont{
    flex-direction: column;
  }
}
