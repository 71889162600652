.details h1,
h2,
h3,
h4,
h5 {
  font-weight: 700;
  font-size: 35px;
  letter-spacing: -0.3px;
  line-height: 1.2em;
}
h2 {
  font-weight: 400 !important;
}

.carousel-container {
  position: relative;
  height: 50%;
  max-height: 50%;
  max-width: 100%;
  margin: 0 auto;
  padding: 10px;
}

.slider-img {
  height: 100vh !important;
  object-fit: cover;
  border-radius: 30px;
}

.text-box {
  min-width: 350px;
  min-height: 300px;
  padding: 50px;
  border-radius: 30px;
  background-color: rgb(64 50 38 / 96%);
  color: white;
  display: flex;
  align-items: left;
  padding-left: 50px;
  gap: 20px;
  justify-content: center;
  position: absolute;
  flex-direction: column;
  top: 50%;
  left: 60%;
  right: 2%;
  transform: translateY(-50%);
  z-index: 1;
}

.text-box h3,
.text-box .txt-h3 {
  font-family: "Space Grotesk" !important;
  font-size: 60px;
  color: white !important;
  font-weight: 700;
}

.text-box h2 {
  color: white !important;
  font-family: Roboto;
  font-size: 18px;
  font-weight: 700;
}

.slide-image {
  width: 100%;
  height: 100%;
  /* height: 20%; */
  object-fit: cover;
}

.slick-slider {
  width: 100%;
  height: 100%;
}

.slick-dots {
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
}

.text-box h3,
.text-box .txt-h3 {
  font-family: "Space Grotesk" !important;
  font-size: 60px;
  color: white !important;
  font-weight: 700;
}

.text-box h2 {
  color: white !important;
  font-family: Roboto;
  font-size: 18px;
  font-weight: 700;
}

.slick-dots {
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
}

.banner-book-now {
  border: 1px solid #999797;
  padding: 12px;
  inline-size: 160px;
  border-radius: 50px;
  text-align: center;
  color: white;
  background-color: rgb(187, 154, 101);
}
.banner-book-now2 {
  border: 1px solid #999797;
  padding: 12px;
  inline-size: 160px;
  border-radius: 50px;
  text-align: center;
}

.book-now-circle {
  width: 50px;
  height: 50px;
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  rotate: 310deg;
  position: absolute;
  top: 1px;
  left: 140px;
  color: rgb(187, 154, 101);
  background-color: white;
}

.booknow-circle-cont {
  /* display: flex; */
  position: relative;
}

.moreAbout2 {
  display: none;
}
.carousel-control-prev {
  display: none !important;
}
.carousel-control-next {
  display: none !important;
}
/* Responsive Styles */
@media (max-width: 1440px) {
  .slider-img {
    max-height: 100vh;
  }
  .text-box h3,
  .text-box .txt-h3 {
    font-size: 50px;
  }
}

@media (max-width: 1024px) {
  .text-box {
    min-width: 320px;
    padding: 40px;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .text-box h3,
  .text-box .txt-h3 {
    font-size: 45px;
  }

  .text-box h2 {
    font-size: 16px;
  }
}

@media (max-width: 768px) {
  .text-box {
    min-width: 300px;
    padding: 30px;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .text-box h3,
  .text-box .txt-h3 {
    font-size: 35px;
  }

  .text-box h2 {
    font-size: 14px;
  }
  .moreAbout2 {
    background-color: rgb(187, 154, 101);
    display: none;
    border-radius: 30px;
  }
  .slider-img {
    max-height: 70vh;
  }
  .carousel-indicators {
    display: none !important;
  }
}

@media (max-width: 480px) {
  .text-box {
    min-width: 280px;
    min-height: 210px;
    padding: 20px;
    left: 50%;
    transform: translate(-50%, -50%);
    justify-content: center;
    align-items: center;
  }

  .text-box h3,
  .text-box .txt-h3 {
    font-size: 28px;
    text-align: center;
  }

  .text-box h2 {
    font-size: 12px;
    display: none;
  }
  .slider-img {
    max-height: 60vh;
  }
  .moreAbout2 {
    display: block;
  }
  .moreAbout {
    display: none;
  }
  .carousel-indicators {
    display: none !important;
  }
}
