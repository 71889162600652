body {
  padding: 0px;
  margin: 0px;
}
.section1-design-body-cont {
  display: flex;
  justify-content: center;
  align-items: center;
  /* width: 100vw; */
  padding-bottom: 2%;
}

.design-cont {
}

.css-1sj2t5c,
.css-1bwerz7 {
  height: 100% !important;
}
.design-row {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  /* height: 500px; */
  /* background-color: aquamarine; */
}

.design-col {
  width: 100%;
  height: 900px;
  /* border: 1px solid black; */
  /* background-color: #ff1a1a; */
}

/************ LEFT CARD ************************/
.left-col-cont {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 50px;
  height: 100%;
  flex-direction: column;
  /* background-color: blueviolet; */
}

.left-card {
  /* width: 90%;
  height: 400px; */
  border-radius: 30px;
}

/***************left card fiest con**********************/
.left-card-first-cont {
  display: flex;
  border: 1px solid #ddd;
  border-radius: 30px;
  flex-direction: column;
  height: 100%;
  padding: 30px;
}

.left-card-first-cont h2 {
  font-family: Space Grotesk, sans-serif;
  font-weight: 700;
  font-size: 24px;
  line-height: 30px;
  letter-spacing: -0.25px;
  color: #403226;
  text-transform: capitalize;
  font-style: normal;
}

.left-card-first-cont ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
.left-card-first-cont li {
  position: relative;
  display: flex;
  border: 1px solid #ddd;
  border-right: none;
  border-left: none;
  border-top: none;
  justify-content: space-between;
  margin: 10px 0;
}

.left-card-first-cont li a {
  font-family: "Space Grotesk", sans-serif;
  font-weight: 500 !important;
  color: #a08c7a !important;
  text-decoration: none;
  display: inline-block;
  padding: 10px 50px 10px 2px;
  font-size: 15px;
  transition: color 0.3s ease;
}

.left-card-first-cont li a.active {
  color: #bb9a65 !important; /* Color for the active link */
}

/***************Left card img cont ***********************/
.left-card-img-cont {
  position: relative; /* Important for absolute positioning of overlay */
  /* width: 100%; */
  border-radius: 30px;
  padding: 5%;
  height: 400px;
  background-image: url("../../Assets/images/callnow.avif");
  display: flex;
}

.img-overlay {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 30px;

  background: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.5));
  z-index: -2;
}

.text-overlay {
  color: white;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.text-overlay h4 {
  font-family: Space Grotesk, sans-serif;
  font-weight: 700;
  line-height: 40px;
  letter-spacing: -0.25px;
  text-transform: none;
  font-style: normal;
  width: max-content;
  color: white !important;
}
.text-overlay h3 {
  font-family: Space Grotesk, sans-serif;
  font-weight: 700;
  line-height: 46px;
  letter-spacing: -0.25px;
  text-transform: none;
  font-style: normal;
  color: white !important;
}

.register-btn {
  background-color: #4caf50;

  border-radius: 30px;
  width: 50%;
  /* margin-left: 100px;
  margin-right: 100px; */
  margin-top: 20px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: background-color 0.3s ease; /* Adds a smooth transition effect */
}

.register-btn p {
  color: white;
  font-size: 16px;
  margin: 0;
}

/* Optional: Hover effect */
.register-btn:hover {
  background-color: #45a049; /* Darker green on hover */
}
.text-overlay span {
  display: flex;
  align-items: center;
  justify-content: center;
  /* font-size: 1.2em; */
}

/*****************Left Third Card************************/
.left-third-card {
  padding: 5px;
  font-family: Space Grotesk, sans-serif;
  font-weight: 700;
  font-size: 24px;
  line-height: 30px;
  letter-spacing: -0.25px;
}
.left-third-card h2 {
  font-family: Space Grotesk, sans-serif;
  font-weight: 700;
  font-size: 34px;
  line-height: 30px;
  letter-spacing: -0.25px;
}
.file-cont {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 30px;
  margin-top: 30px;
  align-items: center;
}

.file {
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  font-size: 15px;
  font-family: Space Grotesk, sans-serif;
  font-weight: 600;
  font-style: normal;
  height: 70px;
  border: 1px solid #ddd;
  border-radius: 50px;
  padding-left: 10px;
  padding-right: 5px;
}
.second-file {
  background-color: #bb9a65;
}

.file-iiner-cont {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.file-iiner-cont span {
  padding-left: 20px;
}
.download {
  font-size: 20px;
}
.download-circle {
  width: 60px;
  height: 60px;
  background-color: rgb(250, 250, 250);
  display: flex;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
}
/*****************Right Card**********************/
/*****************Right Second Card***************/
.heading-card{
  border: 1px solid #ddd;
  border-radius: 30px;
  padding:30px;
  margin-bottom: 30px;
}

.right-card2-cont {
  display: block;
  width: 100%;
  /* justify-content: center;
  align-items: center; */
  /* background-color: aliceblue !important; */
}
.right-card-2-cont {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.right-card-2-first-div {
  display: flex;
  /* background-color: orange; */
  width: 100%;
}

.right-card-2-first-div h4 {
  font-weight: 700;
  font-size: 29px;
  font-family: Space Grotesk, sans-serif;
}
.right-card-2-second-div {
  margin-top: 0px;
}

.right-card2-row {
  display: flex;
  flex-direction: column;
}
.right-card2 {
  /* width: 90%; */
  /* height: 400px; */
  border-radius: 30px;
  /* background-color: blueviolet; */
  display: flex;
  /* justify-content: center; */
  align-items: center;
  padding: 10px;
  padding-top: 50px;
  flex-direction: column; /* Changed from row to column for better alignment */
  /* border-radius: 30px; */
}
.right-card2-row {
  background-color: pink;
  display: contents;
  flex-direction: row;
}

.right-card2-col-cont {
}
.right-card2-col {
  display: flex;
  width: 100%;
  padding: 5px;
  /* background-color: #ff1a1a; */
}

/*****************Right Card*********************/
/*****************Right third card***************/

.right-col-cont {
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  /* gap: 50px; */
  flex-direction: column;
  /* background-color: blue; */
}

.right-card {
  width: 90%;
  /* height: 400px; */
  border-radius: 30px;
  /* background-color: blueviolet; */
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column; /* Changed from row to column for better alignment */
  border-radius: 30px;
}
.right-card img {
  width: 100%;
  height: 100%;
  border-radius: 30px;
}
.right-card-1 {
  width: 90%;
  /* height: 600px; */
}

.right-card-3-row-cont {
  /* background-color: rgb(5, 208, 215); */
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  align-content: center;
  width: 100%;
  /* gap:30px */
}

.right-card-3-col {
  height: 300px;
  width: 100%;
  /* background-color: rgb(28, 232, 41); */
  /* border: 1px solid black; */
  display: flex;
  justify-content: center;
  /* border-radius: 50px; */
  align-items: center;
}
.right-card-inner-cont {
  /* background-color: pink; */
  padding: 0px !important;
}
.right-card-inner-row {
  /* background-color: antiquewhite; */
}

.right-card-3-card {
  background-color: #ff1a1a;
  height: 100%;
  width: 95%;
  border-radius: 30px;
}
.right-card-3-card img {
  width: 100%;
  height: 100%;
  border-radius: 30px;
}

/***********Gallery******************/
.service-gallery-cont {
  /* background-color: pink; */
  /* gap:30px !important; */
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: space-around;
}
.service-gallery {
  /* display: flex; */
  width: 310px;
  height: 310px;
}

/* Base styles for service-gallery-cont and service-gallery */
.service-gallery-cont {
  display: flex;
  flex-wrap: wrap;
  gap: 10px; /* Adjust as needed */
  justify-content: center;
}

.service-gallery {
  flex: 1 1 calc(50% - 20px); /* Adjust for spacing */
  max-width: calc(50% - 20px); /* Ensures a maximum width */
  box-sizing: border-box;
}

.service-gallery img {
  width: 100%;
  height: auto; /* Maintain aspect ratio */
  display: block;
}

/* Extra small devices (phones, 600px and down) */
@media (max-width: 599px) {
  .service-gallery {
    flex: 1 1 100%;
    max-width: 100%;
  }
}

/* Small devices (phones, 600px to 767px) */
@media (min-width: 600px) and (max-width: 767px) {
  .service-gallery {
    flex: 1 1 calc(50% - 10px); /* Adjust for spacing */
    max-width: calc(50% - 10px);
  }
}

/* Medium devices (tablets, 768px to 991px) */
@media (min-width: 768px) and (max-width: 991px) {
  .service-gallery {
    flex: 1 1 calc(33.33% - 20px);
    max-width: calc(50.33% - 20px);
  }
  .service-gallery img {
    height: 100%;
  }
}

.list-cont {
  list-style-type: none; /* Remove default list styling */
  padding: 0;
}

.list-cont li {
  margin-bottom: 10px;
  color: rgb(64, 50, 38) !important;
}

/* Large devices (desktops, 992px to 1199px) */
@media (min-width: 992px) and (max-width: 1199px) {
  .service-gallery {
    flex: 1 1 calc(33.33% - 20px);
    max-width: calc(50.33% - 20px);
  }
  .service-gallery img {
    height: 100%;
  }
  .specillay-col {
    width: 100% !important;
  }
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
  .service-gallery {
    flex: 1 1 calc(33.33% - 20px);
    max-width: calc(50.33% - 20px);
  }
  .service-gallery img {
    height: 100%;
  }
}

/* Responsive Styles */

/* Mobile Devices (max-width: 600px) */
@media only screen and (max-width: 600px) {
  .design-row {
    flex-direction: column;
  }

  .right-card2-row {
    flex-direction: column;
  }

  .left-card,
  .right-card {
    width: 100%;
    height: auto;
  }

  .right-card-1 {
    height: auto;
  }

  .right-card-3-row-cont {
    flex-direction: column;
  }

  .right-card-3-col {
    width: 100%;
    margin-bottom: 20px;
  }

  .right-card2 {
    padding: 20px;
  }

  .left-card-img-cont {
    /* height: auto; */
  }

  .text-overlay h4 {
    /* font-size: 18px; */
  }

  .text-overlay h3 {
    /* font-size: 18px; */
  }
  .left-card-first-cont {
    margin-top: 20px;
  }
}

/* Tablets (max-width: 768px) */
@media only screen and (max-width: 768px) {
  .design-row {
    flex-direction: column;
  }

  .left-card,
  .right-card {
    width: 100%;
  }

  .right-card2-row {
    flex-direction: column;
  }

  .right-card-3-row-cont {
    flex-direction: column;
  }

  .right-card-3-col {
    width: 100%;
    margin-bottom: 20px;
  }
  .right-small-screen {
    display: contents;
  }
  .right-card-3-row-cont {
    /* display: contents; */
  }
  .design-col {
    display: contents;
  }
  .left-design-cont {
    /* display: none; */
  }
  .order-md-2 {
    order: 2 !important;
    display: contents;
  }
  .right-card-3-col {
    /* width: 300px; */
  }
  .specillay-col-left {
    width: 100% !important;
  }
  .specifically-right-col {
    width: 100% !important;
  }
  .left-card-img-cont {
    width: 100% !important;
    justify-content: center;
  }
  .left-card-first-cont {
    margin-top: 20px;
  }
  .year-card {
    display: none;
  }
}

/* Large Devices (max-width: 992px) */
@media (max-width: 1024px) {

  .service-gallery-cont {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: space-around;
  }
  .service-gallery {
    display: flex;
    width: 250px;
    height: 250px;
  }
  .right-card2-col-cont {
    padding-right: 10px;
  }
  .design-row {
    flex-direction: column;
  }

  .left-card,
  .right-card {
    width: 100%;
  }

  .right-card2-row {
    flex-direction: column;
  }

  .right-card-3-row-cont {
    flex-direction: column;
  }
  .specifically-right-col {
    display: contents;
  }
  .specillay-col-left {
    width: 100% !important;
    /* padding: 35px; */
  }
  .left-card-img-cont {
    width: 100% !important;
    justify-content: center;
    align-items: center;
  }
  .css-1pvcaf {
    width: 100% !important;
    padding: 0px !important;
    text-align: center !important;
  }
  .special-display-2 {
    display: none;
  }
}

@media (min-width: 1024px) {
  .css-1pvcaf {
    animation-delay: 0ms;
    width: 100%;
  }
}


@media (min-width: 1025px){
  .special-right-card{
    display: none;
  }
}

.left-card-img-cont {
  display: flex;
  justify-content: center !important;
}

@media (max-width: 767px) {
  .css-1bwerz7 img {
    height: 100% !important;
  }
}
