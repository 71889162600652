/* Existing styles */
.bg-black {
    margin-top: 109px;
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-black-rgb), var(--bs-bg-opacity)) !important;
  }
  
  .accordion-item {
    color: var(--bs-accordion-color);
    background-color: var(--bs-accordion-bg);
    border: var(--bs-accordion-border-width) solid var(--bs-accordion-border-color);
    padding-top: 17px;
    margin-top: 19px;
  }
  
  .bg-black {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-black-rgb), var(--bs-bg-opacity)) !important;
    margin-top: 59px;
  }
  
  .titl {
    color: #e5646e;
    list-style: none;
    text-decoration: none;
  }
  
  .footer-color {
    background: #FFFDD0;
    padding-top: 45px;
    padding-bottom: 15px;
  }
  
  .footer-about p,
  .footer-newsletter p,
  .copyright p {
    /* color: black !important; */
  }
  
  .footer-title {
    color: #e5646e !important;
  }
  
  .footer-title ul li {
    /* color: black !important; */
  }
  
  .footer-title ul li a {
    color: #e5646e !important;
  }
  
  .desc {
    /* color: black !important; */
  }
  
  a {
    color: white !important;
    text-decoration: underline;
  }
  .footer-list li a{
    color:black !important
  }
  .google-plus {
    color:black !important
  }
  .copyright a{
    color: black !important;
  }
  ::marker {
    unicode-bidi: isolate;
    font-variant-numeric: tabular-nums;
    color: black;
    text-transform: none;
    text-indent: 0px !important;
    text-align: start !important;
    text-align-last: start !important;
  }
  
  .social-icon-style {
    display: flex;
    justify-content: center;
    align-items: center;

  }
  
  .social-icon-style a {
    color:black !important
  }
  
  .facebook {
    /* padding: 0 25px; */
  }
  
  .footer-logo img {
    width: 150px;
    border-radius: 50%;
  }
  
  .copyright .skdm {
    color: #e5646e !important;
  }
  
  .soical-ul {
    display: flex;
    justify-content: space-around;
    padding: 0px;
  }
  .footer-list li a{
    text-decoration: none !important;
  }
  /* .footer-list{
    display: flex;
    back
  } */

  /* Responsive styles */
  .google-plus{
    padding:5px
  }
  /* For screens between 600px and 786px */
  @media screen and (max-width: 786px) {
    .bg-black {
      margin-top: 30px;
    }
  
    .accordion-item {
      padding-top: 10px;
      margin-top: 10px;
    }
  
    .footer-color {
      padding-top: 30px;
      padding-bottom: 10px;
    }
  
    .footer-logo img {
      width: 120px;
    }
  
    .soical-ul {
      flex-direction: row;
      align-items: center;
    }
    /* .footer-link-li{
      display: flex !important;
      flex-direction: column !important;
      justify-content: center !important;
      align-items: center !important;
    } */
  }

  /* For screens below 600px */
  @media screen and (max-width: 600px) {
    .bg-black {
      margin-top: 20px;
    }
  
    .accordion-item {
      padding-top: 8px;
      margin-top: 8px;
    }
  
    .footer-color {
      padding-top: 20px;
      padding-bottom: 8px;
    }
  
    .footer-logo img {
      width: 100px;
    }
  
    .soical-ul {
      flex-direction: row;
      align-items: center;
    }
  
    .facebook {
      padding: 0 15px;
    }
    .footer-link-li{
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
    .footer-title{
      /* text-align: center; */
    }

  }
  