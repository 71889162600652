.about-banner {
    background-image: url('../../Assets//images/about_banner_11zon.png');
    background-position: center;
    background-size: cover;
    width: 100%;
    background-repeat: no-repeat;   
    background-attachment: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px; /* Add padding for smaller screens */
    height: 500px;
}

.about-banner h1 {
    /* font-size: 40px; */
    letter-spacing: -.3px;
    line-height: 1.08em;
    font-family: Montserrat, serif;
    color: #ffffff !important;
    font-weight: 700;
    display: flex;
    justify-content: center;
    text-align: center;
}

/* Responsive styles */
@media screen and (min-width: 768px) {
    .about-banner {
        height: 500px;
    }
    .about-banner h1 {
        font-size: 50px;
    }
}

@media screen and (min-width: 1024px) {
    .about-banner {
        /* height: 600px; */
    }
    .about-banner h1 {
        font-size: 60px;
    }
}

@media screen and (max-width: 767px) {
    .about-banner {
        height: 300px;
    }
    .about-banner h1 {
        font-size: 30px;
    }
}

@media screen and (max-width: 480px) {
    .about-banner {
        height: 250px;
    }
    .about-banner h1 {
        font-size: 24px;
    }
}
