.detai{
    background-image: url(https://cozy.qodeinteractive.com/wp-content/uploads/2016/11/p2-img1.jpg);
    background-repeat: no-repeat;
    background-size: cover   ;
    height: 45vh;
}

.detai h1, h2, h3, h4, h5 {
    font-family: Montserrat, serif;
    color: #212121;
    font-weight: 700;
    font-size: 35px;
    letter-spacing: -.3px;
    line-height: 1.2em;
}

.black-pad{
    background: #2f2f2f;
    width: 100%;
    height: 230px;
}

.linkkingg-btn {
 margin-top: 20px;
}

@media (max-width: 992px){
    .card-toop{
       margin-bottom: 30px;
    }

    .praa{
       padding: 0 20px;
    }

    .linkkingg-btn a {
       color: #69c5d3 !important;
       font-size: 18px;
       font-weight: 600;
       padding: 0 20px;
       text-decoration: none;
       font-style: none;
   }

   .beautih1{
       padding:  0 20px;
   }
}