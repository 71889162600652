.logo-imgg{
    width: 100px;
}
.logo-imgg img{
    width: 100%;
}

.navbar-expand-lg .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding: 0 50px;
    padding-left: var(--bs-navbar-nav-link-padding-x);
    color: black !important;
    font-size: 18px;
    line-height: 20px;
    font-weight: 700;
    color: #313131;
    font-family: Montserrat, serif;
}

.dropdown-item {
    display: block;
    width: 100%;
    padding: var(--bs-dropdown-item-padding-y) var(--bs-dropdown-item-padding-x);
    clear: both;
    font-weight: 400;
    color:black !important;
    text-align: inherit;
    text-decoration: none;
    white-space: nowrap;
    background-color: transparent;
    border: 0;
    border-radius: var(--bs-dropdown-item-border-radius, 0);
}

.btn-enq{
    background: #ec5f73 !important;
}


.navbar {
    --bs-navbar-padding-x: 0;
    --bs-navbar-padding-y: 0 !important;
    --bs-navbar-color: rgba(var(--bs-emphasis-color-rgb), 0.65);
}

.sub-menu {
    display: none;
  }
  
  .sub-menu.open {
    display: block;
  }
  
  /* Optional: Add a transition for smooth opening/closing */
  .sub-menu {
    transition: all 0.3s ease;
  }