
  
  .gallery-image {
    margin-bottom: 20px;
    overflow: hidden;
    position: relative;
    border-radius: 20px;
    padding-top: 75%; /* This sets a 4:3 aspect ratio (adjustable) */

  }
  
  .gallery-image img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover; /* Ensures the image covers the container while keeping its aspect ratio */
    transition: transform 0.3s ease-in-out;
  }
  
  .gallery-image:hover img {
    transform: scale(1.05); /* Slight zoom effect on hover */
  }
  
  @media (max-width: 768px) {
    .gallery-image img {
      margin-bottom: 15px;
    }
  }
  
  @media (max-width: 576px) {
    .gallery-image img {
      margin-bottom: 10px;
    }
  }
  