.section-whychoose-cont {
  display: flex;
  width: 100vw;
}

.whychoose2-row {
  display: flex;
  flex-direction: row;
}

.whychoose2-col {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.whychose-card-cont {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: relative;
}

.card-alingment {
  width: auto;
  height: 100%;
  display: flex;
  position: relative;
}

.whychoose2-card {
  width: 450px;
  height: 450px !important;
  border-radius: 30px !important;
  display: flex;
  justify-content: end;
  position: relative;
}

.whychoose2-card img {
  width: 100% !important;
  height: 100% !important;
  border-radius: 30px !important;
}

.card2 {
  width: 280px;
  height: 280px;
  position: absolute;
  margin-top: 200px;
  margin-right: 50%;
  border-radius: 30px;
  background-color: green;
}

.card2 img {
  width: 100% !important;
  height: 100% !important;
  border-radius: 30px !important;
}

.year-card {
  width: 200px;
  height: 100px;
  padding-right: 30px;
  position: absolute;
  margin-right: 50%;
  margin-bottom: 50%;
}

.started-txt {
  color: var(--pbmit-xinterio-global-color);
  margin: 0 0 5px 10px;
  text-transform: uppercase;
  font-weight: 400;
  font-size: 28px;
  line-height: 24px;
}

.year-txt {
  color: var(--pbmit-xinterio-global-color);
  font-family: Space Grotesk, sans-serif;
  font-weight: 700;
  font-size: 70px;
  line-height: 80px;
  letter-spacing: 10px;
  text-transform: none;
  font-style: normal;
}

.pbmit-tab-link  span a{
  text-decoration:  none !important;
}
.pbmit-tab-link  span a:hover{
  color:#e5646e !important;
}

.elementor-button-wrapper span{
  text-decoration: none !important;
}
/* Responsive Styles */
@media (max-width: 1300px) {
  .whychoose2-card {
    width: 350px;
    height: 350px !important;
  }

  .card2 {
    width: 220px;
    height: 220px;
    margin-top: 150px;
  }

  .year-card {
    width: 160px;
    height: 80px;
  }

  .started-txt {
    font-size: 24px;
    line-height: 22px;
  }

  .year-txt {
    font-size: 60px;
    line-height: 70px;
  }
}

@media (max-width: 768px) {
  .whychoose2-row {
    flex-direction: column;
    align-items: center;
  }
  .special-col {
    width: 100% !important;
    display: flex;
  }
  .whychoose2-card {
    /* width: 300px;
    height: 300px !important; */
    width: fit-content !important;
    height: fit-content;
  }

  .card2 {
    /* width: 180px;
    height: 180px; */
    margin-top: 120px;
    margin-right: 0;
    right: 40%;
    transform: translateX(-50%);
  }

  .year-card {
    /* width: 140px;
    height: 70px; */
    margin-right: 0;
    right: 50%;
    transform: translateX(-50%);
  }

  .started-txt {
    font-size: 20px;
    line-height: 18px;
    text-align: center;
  }

  .year-txt {
    font-size: 50px;
    line-height: 60px;
    text-align: center;
  }
  .pbmit-tabs {
    flex-direction: column;
  }

  .pbmit-tab-content-wrapper {
    padding: 0 !important;
    margin: 10px;
  }
  .elementor-widget-container{
    padding-bottom: 20px;
  }
}
.pbmit-tab-content-title,
.pbmit-tab-link {
  width: 100% !important;
}

@media (max-width: 480px) {
  .whychoose2-card {
    width: 240px;
    padding-top:5%;
    /* height: 240px !important; */
  }
  .special-col-2 {
    display: contents;
  }

  .card2 {
    width: 150px;
    height: 150px;
    display: none;
    margin-top: 100px;
  }


  .started-txt {
    /* font-size: 18px; */
    line-height: 16px;
  }
  .year-card {
    width: 120px;
    height: 60px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 10px; 
  }
  
  .started-txt {
    /* font-size: 18px; */
    line-height: 16px;
    margin: 0; /* Remove margin */
    text-align: center;
  }
  
  .year-txt {
    /* font-size: 40px; */
    line-height: 50px;
    margin: 0; /* Remove margin */
    text-align: center;
  }
  
}


@media (max-width:1023px){
  .whychoose2-row{
    flex-direction: column;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .special-col {
    display: contents;
  }
  .card2 {
    width: 150px;
    height: 150px;
    display: none;
    margin-top: 100px;
  }
  .year-card {
    width: 120px;
    height: 60px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 10px; 
  }
  .special-col-2 {
    display: contents;
  }
  .card-alingment{
    padding-top:10px
  }
}