.img-banner{
    background-image: url(https://cozy.qodeinteractive.com/wp-content/uploads/2016/11/paralax-1.jpg);
    width: 100%;
    height: 700px;
    background-position: 50% 0px;
    background-attachment: fixed;
}

.stylee{
    display: flex;
    justify-content: start;
    align-items:center;
    padding-left: 110px;
    height: 100%;
    font-family: Montserrat;
    font-size: 80px;
    font-weight: 600;
    text-align: left;
    color: #ffffff;
}
.stylee h1{
    
    font-family: Montserrat;
    font-size: 80px;
    font-weight: 600;
    text-align: left;
    color: #ffffff;
}
.detaol-p p{
    font-size: 25px;
    line-height: 40px;
    font-weight: 200;
    text-align: left;
    color: #ffffff;
    /* padding-left: 110px; */
}

.parents{
    display: flex;justify-content: center; align-items: center;
}

.image-service{
    background: url(https://cozy.qodeinteractive.com/wp-content/uploads/2016/11/services-img-1.jpg);
 width: 100%;
    height: 400px;
    background-size: cover;
    /* object-position: center; */
    background-position: center;
}
.image-servic {
    background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), 
                url(https://cozy.qodeinteractive.com/wp-content/uploads/2016/11/img-2-1.jpg);
    width: 100%;
    height: 400px;
    background-size: cover;
    background-position: center;
}


.image-servi{
    background: url(https://cozy.qodeinteractive.com/wp-content/uploads/2016/11/services-img-3.jpg);
    width: 100%;
    height: 400px;
    background-size: cover;
    background-position: center;
}


.card-tittle h2{
    font-family: Montserrat, serif !important;
    justify-content: center;
    align-items: left !important;
    text-align: start;
    padding-top: 84px;
    padding-left: 35px;
    font-size: 35px;
    font-weight: 700;
    letter-spacing: -.3px;
    line-height: 1.2em;
}
.card-tittle{
    display: flex;
    justify-content: start;
    align-items: center;
    text-align: center;
}

.go-link{
    display: flex;
    justify-content: start;
    margin-left: 43px;
}

.p-details{
    align-items: start;
}

.anch{
    background: #69c5d3;
    padding: 12px 37px;
    border-radius: 25px;
    width: max-content;
}
.anch a{
    color: #ffffff;
    text-decoration: none;

}

.anch:hover{
    background:black;
    color: #ffffff;
    
}

.loade {
    border: 10px solid #f3f3f3;
    border-radius: 50%;
    border-top: 10px solid #565656;
    width: 190px;
    height: 190px;
    -webkit-animation: spin 2s linear infinite; /* Safari */
    animation: spin 2s linear infinite;
  }
  
  /* Safari */
  @-webkit-keyframes spin {
    0% { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); }
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
.loader {
    border: 10px solid #f3f3f3;
    border-radius: 50%;
    border-top: 10px solid #e66071;
    width: 190px;
    height: 190px;
    -webkit-animation: spin 2s linear infinite; /* Safari */
    animation: spin 2s linear infinite;
  }
  
  /* Safari */
  @-webkit-keyframes spin {
    0% { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); }
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }


.service-bg-image{
    background-image: url(https://cozy.qodeinteractive.com/wp-content/uploads/2016/11/img-1-3.jpg);
    width: 100%;
    height: 500px;
    background-repeat: no-repeat;
}

.deta{
    color: #c7b9b9;
}

.service-im-detail{
    padding-top: 15%;
    /* padding-left: 45px; */
}

.deader-deatl h2{
    font-family: Montserrat, serif;
    color: #212121;
    font-weight: 700;
    font-size: 35px;
    letter-spacing: -.3px;
    line-height: 1.2em;

}

.paragraph-detaile p{
    display: flex;
    text-align: start;
}

